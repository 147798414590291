<template>
  <booking-card :isLoading="isLoading">
    <template v-slot:title>
      <h5 class="text-color-kerry font-weight-bold mb-4">
        {{ $t("myShipmentHistory") }}
      </h5>
    </template>
    <div v-if="bookingLists.length > 0">
      <div :key="index" v-for="(booking, index) in bookingLists" class="my-4">
        <p class="f-size-16 font-size-sm-14">
          {{ booking.estimateDeliveryDate }}
        </p>
        <div
          :key="index"
          v-for="(bookdetail, index) in booking.data"
          class="booking-container"
        >
          <div class="p-3 m-0 mw-100 header-container">
            <router-link
              :to="`/my-shipment/details/${lang}?sessionId=${sessionId}&telephone=${
                params.telephone
              }${
                params.trackingNo ? `&trackingNo=${params.trackingNo}` : ``
              }&trackingNoSelected=${bookdetail.shipmentInfo.consignmentNo}`"
              class="text-decoration-none"
            >
              <div class="d-flex align-items-center flex-gap">
                 <div
                  class="icon-box"
        v-bind:style="{
                      backgroundImage: 'url(' + bookdetail.icon.iconUrl +')'
                    }"
      ></div>
                <p
                  class="
                    mb-0
                    font-weight-bold
                    text-dark
                    f-size-16
                    font-size-sm-14
                  "
                >
                  {{ bookdetail.shipmentInfo.consignmentNo }}
                </p>
                <div class="ml-auto d-flex align-items-center">
                  <p
                    class="
                      mb-0
                      text-color-kerry
                      font-weight-bold
                      f-size-16
                      font-size-sm-14
                    "
                  >
                    {{ bookdetail.shipmentStatus[0].statusDescription }}
                  </p>
                  <font-awesome-icon
                    class="text-color-kerry ml-2"
                    icon="angle-right"
                  />
                </div>
              </div>
            </router-link>
          </div>
          <b-container class="p-3 m-0 mw-100">
            <b-row class="no-gutters">
              <b-col class="col-container" cols="5">
                <p class="f-size-14 font-size-sm-12 mb-3">{{ $t("sender") }}</p>
                <p
                  class="
                    font-weight-bold
                    f-size-14
                    font-size-sm-12
                    text-break text-center
                    mb-1
                  "
                >
                  {{ bookdetail.shipmentInfo.senderName }}
                </p>
                <p class="mb-0 f-size-12 font-size-sm-10 text-color-province">
                  {{ `${bookdetail.shipmentInfo.originProvince}` }}
                </p>
              </b-col>
              <b-col class="col-container" cols="2">
                <font-awesome-icon
                  class="text-color-kerry f-size-22"
                  icon="arrow-alt-circle-right"
                />
              </b-col>
              <b-col class="col-container" cols="5">
                <p class="f-size-14 font-size-sm-12 mb-3">
                  {{ $t("recipient") }}
                </p>
                <p
                  class="
                    font-weight-bold
                    f-size-14
                    font-size-sm-12
                    text-break text-center
                    mb-1
                  "
                >
                  {{ bookdetail.shipmentInfo.recipientName }}
                </p>
                <p class="mb-0 f-size-12 font-size-sm-10 text-color-province">
                  {{
                    `${bookdetail.shipmentInfo.destinationProvince} ${bookdetail.shipmentInfo.destinationPostalCode}`
                  }}
                </p>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="nodata-container p-3">
        <p class="text-center mb-0 text-color-kerry">{{ $t("noData") }}</p>
      </div>
    </div>
  </booking-card>
</template>

<script>
import BookingCard from '../../../components/booking-card/BookingCard.vue';
export default {
  components: { BookingCard },
  name:"MyShipmentHistory",
  data: function(){
    return {
      lang: "",
      sessionId: "",
      params:{
        userGUID:"",
        telephone: "",
        trackingNo: ""
      },
      bookingLists:[],
      isLoading:true
    }
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    const {telephone,trackingNo,sessionId} = this.$route.query
    this.lang = this.$route.params.lang ? this.$route.params.lang : "th"
    this.params.telephone = telephone
    this.params.trackingNo = trackingNo
    this.sessionId = sessionId
    if(this.sessionId){
      await this.getUserGUID()
      await this.getShipmentDetail()
    } else {
      this.$router.push('/session-expired')
    }
  },
  methods: {
    getUserGUID: async function(){
      const getUserUrl = `${this.$baseUrl}/kerryApi/user/${this.sessionId}`
      const {result,error} = await this.handlePromise(this.$axios.get(getUserUrl))
      if (error) {
        console.log(error)
        return
      }
      if(result.data.result === 1){
        this.params.userGUID = result.data.detail.userGUID
        if(!this.params.userGUID){
          this.$router.push('/session-expired')
        }else if (!result.data.detail.isLogin){
          const redirectUrl = encodeURIComponent(
            `/my-shipment/${this.lang}?sessionId=${this.sessionId}&telephone=${this.params.telephone}${
                this.params.trackingNo ? `&trackingNo=${this.params.trackingNo}` : ``
              }`
          );
          this.$router.push(`/login?sessionId=${this.sessionId}&redirectUrl=${redirectUrl}`);
        }
      }
    },
    getShipmentDetail: async function(){
      const getShipmentUrl = `${this.$baseUrl}/kerryapi/ShipmentTrackingList`
      
      const telephone = this.params.telephone
      const trackingNo = this.params.trackingNo
      const telephoneLists = telephone ? telephone.split(",") : []
      const trackingNoLists = trackingNo ? trackingNo.split(",") : []
      const userGUID = this.params.userGUID
      const params = {
        telephone: telephoneLists,
        trackingNo: trackingNoLists,
        userGUID
      }
      const {result,error} = await this.handlePromise(this.$axios.post(getShipmentUrl,params))
      if (error) {
        console.log(error)
        return
      }
      if(result.data.result == 1){
        this.bookingLists = result.data.detail
        this.isLoading = false
      }
    },
    handlePromise: function(promise) {
      return promise.then(data => {
        return {
          error:null,
          result: data
        }
      })
      .catch(err => {
        return {
          error: err
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.booking-container {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.nodata-container {
  background-color: #ffffff;
  border-radius: 8px;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-container {
  border-bottom: 1px solid #bebebe;
  cursor: pointer;
}

.col-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-color-province {
  color: #646366;
}

.flex-gap {
  gap: 0.7rem;
}
.icon-box {
    background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 17px;
  height: 21px;
}

@media only screen and (max-width: 576px) {
  .font-size-sm-14 {
    font-size: 14px;
  }

  .font-size-sm-12 {
    font-size: 12px;
  }

  .font-size-sm-10 {
    font-size: 10px;
  }
}
</style>